.tab-items{
  padding-top: 5px;
  border-bottom: 1px solid #dde1e4;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.tab-items__inner{
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
@media(min-width: 992px){
  .tab-items{
    padding-left: 30px;
    padding-right: 30px;
  }
}
.tab-items__border{
  position: relative;
}
.tab-items__border::after{
  content: "";
  border-bottom: 1px solid #dde1e4;
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
}
.tab-items__link{
  margin-right: 25px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
}
.tab-items__link-amount{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edeff6;
  padding: 2px 6px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 9px;
  min-width: 20px;
  min-height: 20px;
}
.tab-items__link.active{
  color: #364ed4;
}
.tab-items__link.disabled{
  color: #717171;
}
.tab-items__link.active::after{
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #364ed4;
  z-index: 1;
}
