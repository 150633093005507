:root {
  --main-color: #364ed4;
  --main-color-additional: #161d5b;
  --border-radius-btn: 3px;
}
.bo-btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px;
  background-color: #f1f3ff;
  border-radius: 5px;
  color: #364ed4;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
}
.bo-btn_theme_blue{
  background-color: #364ed4;
  color: #fff;
}
.bo-btn.active{
  background-color: #364ed4;
  color: #fff;
}
.btn_theme_login{
  background-color: #29c07f;
  color: #fff;
}
.bo-dashboard-navs{
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-bottom: 20px;
}
.bo-dashboard-navs__item{
  padding-right: 8px;
}
.bo-dashboard-content__statistics{
  border-bottom: 1px solid #d7d9e4;
}
.bo-dashboard-content__statistics:last-child{
  border-bottom: none;
}
.bo-dashboard-content__statistics-inner{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding-bottom: 25px;
}
.bo-dashboard-content__statistics-wgt{
  padding: 5px;
  width: 100%;
  flex: 0 0 auto;
}
@media(min-width: 992px){
  .bo-dashboard-content__statistics-wgt{
    width: 25%;
    padding: 0 5px;
  }
}
.bo-dashboard-content__statistics-wgt-inner{
  background-color: #f2f4f5;
  border-radius: 5px;
  padding: 15px 20px;
  height: 100%;
}
.bo-dashboard-content__statistics-value{
  font-size: 30px;
  font-weight: bold;
  color: #000;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-start;
}
.bo-dashboard-content__statistics-value-link{
  color: #364ed4;
}
.bo-dashboard-content__statistics-value-active{
  color: #00ae4c;
}
.bo-dashboard-content__statistics-value-noactive{
  color: #d01a36;
}
.bo-dashboard-content__statistics-name{
  font-size: 14px;
  color: #808a8e;
}
.bo-dashboard-content__statistics-value .bo-dashboard-content__statistics-value-all{
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}
.bo-control-wgt__message{
  display: inline-block;
  padding-left: 10px;
}
.bo-control-wgt__message-demo{
  margin-left: 0;
  padding-left: 0;
}
.bo-account-wgt__nav{
  padding: 0 10px 15px 10px;
}
@media(min-width: 992px){
  .bo-account-wgt__nav{
    padding: 0 30px 15px 30px;
  }
}
.bo-control-wgt__message .control-btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px;
  background-color: #f1f3ff;
  border-radius: 5px;
  color: #364ed4;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
}
.bo-control-wgt__message .control-content{
  margin-top: 5px;
}
.bo-messages-wgt .bo-status-label{
  cursor: pointer;
}
.bo-messages-wgt:first-child {
    border-top: 1px solid #dde8ee;
}
.bo-messages-wgt {
    border-bottom: 1px solid #dde8ee;
    padding: 10px 0;
    position: relative;
}
.bo-messages-wgt__content-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.bo-messages-wgt__name{
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  display: flex;
  align-items: center;
}
.bo-messages-wgt__operator{
  margin-left: 10px;
  font-size: 12px;
  color: #808a8e;
}
.bo-messages-wgt__content-inner{
  display: flex;
  align-items: center;
}
.bo-messages-wgt__copy-icon{
  margin: 0 7px;
  cursor: pointer;
}
.bo-messages-wgt__date{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808a8e;
}
.bo-messages-wgt__content p{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}
.bo-messages-wgt__copy-icon{
  display: block;
  width: 17px;
  height: 17px;
  stroke: #364ed4;
}
.bo-messages-wgt__response{
  padding-top: 5px;
}
.bo-messages-wgt__response-inner{
  border-radius: 3px;
  background-color: #f0f0f0;
  font-size: 14px;
  color: #000;
  padding: 10px 15px;
}
.bo-messages-wgt__response-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #808a8e;
  padding-bottom: 5px;
}
.bo-messages-wgt__reply-btn{
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  z-index: 10;
  display: none;
}
.bo-messages-wgt:hover .bo-messages-wgt__reply-btn{
  display: block;
}
@media(min-width: 992px){
  .bo-messages-wgt {
    padding: 15px 30px;
  }
  .bo-messages-wgt__content-info{
    align-items: flex-start;
  }
}
.account-nav.account-nav__admin{
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--main-color-additional);
  transform: translateX(-100%);
  z-index: 99999;
  overflow: hidden;
}
.account-nav.account-nav__admin .account-nav__background{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99997;
  background: none;
}
.account-nav.account-nav__admin .account-nav-list{
  list-style-type: none;
  padding: 5px 0;
}
.account-nav.account-nav__admin .account-nav-list__item.active .account-nav-list__sub{
  display: block;
}
.account-nav.account-nav__admin .account-nav-list__sub .account-nav-list__link{
  padding-left: 43px;
}
.account-nav.account-nav__admin .account-nav-list__label{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  color: #fff;
  border-radius: 50%;
  background-color: #22ca7b;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.account-nav.account-nav__admin .account-nav-list__role{
  font-size: 14px;
}
.account-nav.account-nav__admin .account-nav-list__personal{
  position: relative;
}
.account-nav.account-nav__admin .account-nav-list__personal::before{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #323B89;
}
.account-nav.account-nav__admin .account-nav-list__personal::after{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #323B89;
}
.account-nav.account-nav__admin .account-nav-list__link{
  position: relative;
  padding: 10px 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-nav.account-nav__admin .account-nav-list__link.active{
  background-color: #323B89;
}
.account-nav.account-nav__admin .account-nav-list__link:hover{
  background-color: #323B89;
}
.account-nav.account-nav__admin .account-nav-list__item{
  position: relative;
}
.account-nav.account-nav__admin .account-nav-list__no-login .account-nav-list__item:first-child:after{
  display: none;
}
.account-nav.account-nav__admin .account-nav-list__no-login{
  padding: 0;
}
.account-nav.account-nav__admin .account-nav-list__no-login .account-nav-list__item:after {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #36373e;
}
.account-nav.account-nav__admin .account-nav-list__no-login .account-nav-list__link{
  padding-top: 15px;
  padding-bottom: 15px;
}
.account-nav.account-nav__admin .account-nav-list__link.active .add-team-btn__icon{
  stroke: #364ED4;
}
.account-nav.account-nav__admin .account-nav-list__name{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.account-nav.account-nav__admin .account-nav-list__name img{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.account-nav.account-nav__admin .account-nav-list__link.active .account-nav-list__name{
  font-weight: bold;
}
.account-nav.account-nav__admin .account-nav-list__icon{
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 10px;
}
.account-nav.account-nav__admin .account-nav__content{
  flex: 1 0 auto;
}
.account-wrap__admin .account-content__inner{
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.account-wrap__admin .account-content__content{
   flex: 1 0 auto;
}
.account-wrap__admin .account-content__content-inner{
  padding: 30px;
}
.account-wrap__admin .account-content__header{
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: #000;
  padding: 0 10px;
}
.account-wrap__admin .account-content__headline{
  font-size: 21px;
  padding: 18px 0;
}
.account-wrap__admin .account-content__header-inner{
  display: flex;
  align-items: center;
}
.account-wrap__admin .standart-table{
  display: none;
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.account-wrap__admin .standart-table__row:hover{
  cursor: pointer;
  background-color: #f4f4f5;
}
.account-wrap__admin .standart-table__demo tr.standart-table__row::after{
  background: none !important;
}
.account-wrap__admin .standart-table__demo .standart-table__row:hover + tr{
  background-color: #f4f4f5;
}
.account-wrap__admin .standart-table__demo .standart-table__row .standart-table__msg-resolution-cell{
  padding-top: 0;
}
.account-wrap__admin .standart-table tr th{
  font-size: 12px;
  color: #626e77;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.account-wrap__admin .standart-table tr td{
  font-size: 14px;
}
.account-wrap__admin .standart-table__nav{
  display: flex;
  align-items: center;
}
.account-wrap__admin .standart-table__nav-btn{
  border: none;
  background: none;
  position: relative;
  margin-right: 10px;
}
.account-wrap__admin .standart-table__nav-btn svg{
  width: 16px;
  height: 17px;
}
.account-wrap__admin .standart-table__msg{
  color: #000;
}
.standart-table__msg-resolution{
  border-radius: 3px;
  background-color: #f0f0f0;
  padding: 10px 15px;
}
.standart-table__msg-resolution-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  font-size: 13px;
  color: #808a8e;
}
.standart-table__msg-resolution-txt{
  font-size: 14px;
  color: #000;
}
.field-wrap{
  color: #000;
  padding-bottom: 15px;
  position: relative;
}
.field-wrap__link{
  display: flex;
  align-items: center;
}
.field-wrap__label-link{
  font-size: 16px;
  color: #000;
  margin-right: 15px;
  margin-top: 7px;
}
.field-wrap__inner{
  position: relative;
}
.field-wrap__label{
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}
.field-wrap__input-area{
  height: 140px;
  resize: none;
}
.login-admin__submit button,
.field-wrap__submit button{
  position: relative;
}
.login-admin__submit .loader,
.field-wrap__submit .loader{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  height: 37px;
  width: 60px;
  text-align: center;
  padding: 1em;
  margin: 0 auto 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  vertical-align: top;
}
.login-admin__submit svg path,
.login-admin__submit svg rect,
.field-wrap__submit svg path,
.field-wrap__submit svg rect{
  fill: #fff;
}
.message-tags{
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 -4px 15px -4px;
}
.message-tags__item{
  padding: 0 4px;
}
.message-tags__btn_new{
  background-color: #ffeaee;
  color: #a51734;
}
.message-tags__btn_progress{
  background-color: #fae4d0;
  color: #82491e;
}
.message-tags__btn_done{
  background-color: #cff1df;
  color: #1a5f30;
}
.message-wgt{
  border: 1px solid #e5e5e5 ;
  border-radius: 5px;
  padding: 12px 15px;
  margin-bottom: 15px;
  position: relative;
}
.message-wgt:hover{
  border: 1px solid #364ed4;
}
.message-wgt__inner_close:hover{
  cursor: pointer;
}
.message-wgt__overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: none;
}
.message-wgt-item{
  border: 1px solid #afb2ba;
  border-radius: 5px;
  padding: 12px 15px;
  margin-top: 15px;
}
.message-wgt-item__name{
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}
.message-wgt-item__description{
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
}
.message-wgt__text {
  line-height: 1.5;
}
.message-wgt-item__answer{
  border-radius: 3px;
  background-color: #f0f0f0;
  padding: 10px 15px;
}
.message-wgt-item__answer-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #808a8e;
}
.message-wgt-item__answer-text{
  font-size: 14px;
  line-height: 1.5;
  color: #000;
}
.message-wgt-popup__nav{
  padding-top: 15px;
}
.message-wgt-popup__nav-btn:first-child{
  margin-bottom: 15px;
}
@media(min-width: 768px){
  .message-wgt__overlay{
    display: none !important;
  }
}
.message-wgt__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.message-wgt__header-inner{
  display: flex;
  align-items: center;
}
.message-wgt__name-user{
  color: #808a8e;
  font-size: 12px;
  margin-right: 10px;
}
.message-wgt__info{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.massage-tag__mob{
  display: flex !important;
}
.massage-tag__mob .message-tag__item:first-child{
  padding-left: 0;
}
@media(min-width: 768px){
  .message-wgt__info{
    width: auto;
    justify-content: flex-start;
  }
  .massage-tag__mob{
    display: none !important;
  }
}
.message-wgt__name{
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-right: 10px;
}
.message-wgt__name_new{
  font-weight: bold;
}
.message-wgt__date{
  font-size: 13px;
  color: #808a8e;
}
.massage-tag{
  display: none;
  list-style-type: none;
}
.message-wgt__name-user{
  display: none;
}
.message-wgt__footer{
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.message-wgt__name-user.message-wgt__name-user-mob{
  display: block;
}
@media(min-width: 768px){
  .massage-tag{
    display: flex;
    align-items: center;
  }
  .message-wgt__name-user{
    display: block;
  }
  .message-wgt__name-user.message-wgt__name-user-mob{
    display: none;
  }
}
.message-tag__item{
  padding-left: 5px;
}
.message-tag__label{
  display: block;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 16px;
}
.message-tag__label_new{
  background-color: #ffeaee;
  color: #a51734;
}
.message-tag__label_progress{
  background-color: #fae4d0;
  color: #82491e;
}
.message-tag__label_done{
  background-color: #cff1df;
  color: #1a5f30;
}
.message-wgt__read{
  border-color: #e0e5e6;
  background-color: #f2f4f5;
}
.message-wgt__not-read .message-wgt__name,
.message-wgt__not-read .message-wgt__text{
  font-weight: bold;
}
.message-wgt__text_opened{
  font-weight: 500 !important;
}
.message-wgt__text{
  font-weight: 500;
  padding-bottom: 10px;
  font-size: 14px;
}
.message-wgt__text_new{
  font-weight: bold;
}
.message-wgt__inner_opened .message-wgt__name{
  font-weight: 500 !important;
}
.massages-list__panel .message-wgt__answer{
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.message-wgt__answer-header{
  color: #808a8e;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}
.message-wgt__answer-text{
  font-size: 14px;
}
.answer-form__label{
  background-color: #f0f0f0;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-weight: 500;
}
#modal-container.two .modal.modal__appeal{
  max-width: 700px;
}
.answer-form__nav-col:first-child {
    margin-bottom: 15px;
}
.bo-appeal-content__text{
  padding-bottom: 10px;
}
.bo-appeal-content__text-inner{
  border-radius: 3px;
  background-color: #f0f0f0;
  padding: 10px 15px;
  font-size: 14px;
}
.bo-appeal-content__field-status{
  width: 50%;
}
.account-wgt__sep{
  width: 100%;
  height: 1px;
  background-color: #dde1e4;
}
.account-wrap__admin .dashboard-navs{
  display: flex;
  list-style-type: none;
}
.account-wrap__admin .dashboard-navs__item{
  padding: 0 4px;
}
.account-wrap__admin .dashboard-navs__item:first-child{
  padding-left: 0;
}
.account-wrap__admin .dashboard-navs__item:last-child{
  padding-right: 0;
}
.account-wrap__admin .dashboard-navs__btn{
  background-color: #262951;
  color: #fff;
  border-radius: 5px;
}
.account-wrap__admin .dashboard-navs__btn.active,
.account-wrap__admin .dashboard-navs__btn:hover{
  background-color: var(--main-color);
}
.account-wrap__admin .dashboard-content__statistics{
  padding: 20px 0 10px 0;
}
.account-wrap__admin .dashboard-content__statistics-inner{
  border: 2px solid #000;
  border-radius: 5px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.account-wrap__admin .dashboard-content__statistics-wgt{
  padding: 15px 0;
  width: 50%;
  flex: 0 0 auto;
}
.account-wrap__admin .dashboard-content__statistics-wgt_border{
  border-top: 1px solid #dde1e4;
}
.account-wrap__admin .account-wgt__dashboard{
  padding: 5px 10px 10px 10px;
  overflow: hidden;
}
.account-wrap__admin .account-wgt__dashboard{
  padding: 5px 10px 10px 10px;
  overflow: hidden;
}
.account-wrap__admin .dashboard-wgts{
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 10px 10px;
  margin: 0 -20px;
}
.account-wrap__admin .dashboard-wgt{
  width: 50%;
  flex: 0 0 auto;
  padding: 10px;
  flex-shrink: 0;
}
.account-wrap__admin .dashboard-mob-nav{
  padding-bottom: 15px;
  border-bottom: 1px solid #dde1e4;
  margin: 0 20px;
}
.account-wrap__admin .dashboard-mob-nav__list{
  display: flex;
  align-items: center;
  list-style-type: none;
}
.account-wrap__admin .dashboard-mob-nav__item{
  padding: 0 10px;
}
.account-wrap__admin .dashboard-mob-nav__item:first-child{
  padding-left: 0;
}
.account-wrap__admin .dashboard-mob-nav__item:last-child{
  padding-right: 0;
}
.account-wrap__admin .dashboard-mob-nav__button{
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.account-wrap__admin .dashboard-mob-nav__button.active{
  color: #364ed4;
  position: relative;
}
.account-wrap__admin .dashboard-mob-nav__button.active::after{
  content: '';
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 10;
  width: 100%;
  height: 2px;
  background-color: #364ed4;
}
@media(min-width: 768px){
  .account-wrap__admin .dashboard-mob-nav{
    display: none;
  }
  .account-wrap__admin .dashboard-wgt{
    width: 25%;
  }
}
@media(max-width: 767px){
  .account-wrap__admin .account-content__headline-dashboard{
    display: none;
  }
  .account-wrap__admin .dashboard-notifications,
  .account-wrap__admin .dashboard-content__inner{
    display: none;
  }
  .account-wrap__admin .dashboard-notifications.active,
  .account-wrap__admin .dashboard-content__inner.active{
    display: block;
  }
}
.account-wrap__admin .dashboard-wgt:hover .dashboard-wgt__inner{
  border: 1px solid #364ed4;
}
.account-wrap__admin .dashboard-wgt__inner{
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 100%;
  position: relative;
}
.account-wrap__admin .dashboard-wgt__label{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  background-color: #22ca7b;
  color: #fff;
  font-size: 13px;
  border-radius: 50%;
  z-index: 10;
}
.account-wrap__admin .dashboard-wgt__icon{
  width: 30px;
  height: 30px;
}
.account-wrap__admin .dashboard-wgt__content{
  padding-top: 20px;
}
.account-wrap__admin .dashboard-wgt__headline{
  font-size: 18px;
  margin-bottom: 3px;
  color: #000;
}
.account-wrap__admin .dashboard-wgt__value{
  font-size: 13px;
  line-height: 1.38;
  color: #808a8e;
}
.account-wrap__admin .account-wrap__admin .dashboard-wgt__btn{
  display: block;
  width: 100%;
  border-radius: 7px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e5e5e5;
  background-color: #fff;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 700;
}
.account-wrap__admin .dashboard-notifications{
  padding: 15px 20px;
}
@media(min-width: 768px){
  .account-wrap__admin .dashboard-notifications{
    border-top: 1px solid #dde1e4;
  }
}
.account-wrap__admin .dashboard-notifications__header{
  display: none;
  padding-bottom: 10px;
}
.account-wrap__admin .account-content__headline-notification{
  font-size: 18px;
}
.account-wrap__admin .dashboard-notifications__header-inner{
  display: flex;
  align-items: center;
}
@media(min-width: 768px){
  .account-wrap__admin .dashboard-notifications__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.account-wrap__admin .dashboard-notifications__header-amount{
  padding: 2px 6px;
  background-color: #ededed;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  color: #000;
}
.account-wrap__admin .dashboard-notifications__clear{
  color: #364ed4;
  font-size: 14px;
  background: none;
  border: none;
  display: none;
}
@media(min-width: 768px){
  .account-wrap__admin .dashboard-notifications__clear{
    display: block;
  }
}
.account-wrap__admin .dashboard-notifications-wgt{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
  padding: 7px 10px;
  border-radius: 2px;
  cursor: pointer;
}
.account-wrap__admin .dashboard-notifications-wgt:hover{
  background-color: #f4f4f5;
}
.account-wrap__admin .dashboard-notifications-wgt__name{
  font-size: 14px;
}
.account-wrap__admin .dashboard-notifications-wgt__date{
  color: #a7a7a7;
  font-size: 14px;
}
.search-btn{
  background: none;
  border: none;
}
.order-search-icon{
  width: 17px;
  height: 17px;
  stroke: #626E77;
  margin-left: 15px;
  display: block;
}
.search-orders-wrap{
  position: relative;
}
@media(min-width: 768px){
  .search-orders{
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
  }
}
.search-close{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
}
.search-close__icon{
  width: 17px;
  height: 17px;
  fill: #626E77;
  display: block;
}
.search-field-wrap{
  position: relative;
  width: 100%;
}
.search-field{
  border: 1px solid #dde1e4;
  border-radius: 20px;
  height: 32px;
  font-family: sans-serif;
  display: block;
  width: 100%;
  padding-left: 35px;
  font-size: 14px;
  margin-top: 10px;
}
@media(max-width: 767px){
  .search-field{
    font-size: 16px;
  }
}
.search-submit{
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
}
@media(min-width: 768px){
  .search-field{
    margin-top: 0;
  }
  .search-field-wrap{
    padding-left: 15px;
  }
  .search-field-wrap{
    width: 340px;
  }
  .search-submit{
    left: 10px;
  }
}
@media(min-width: 768px){
  .account-wrap__admin .dashboard-content__statistics-inner{
    padding: 0 10px;
  }
  .account-wrap__admin .dashboard-content__statistics-wgt{
    padding: 15px;
    width: 25%;
  }
  .account-wrap__admin .dashboard-content__statistics-wgt_border{
    border-bottom: none;
  }
}
.account-wrap__admin .dashboard-content__statistics-value{
  font-size: 30px;
  font-weight: bold;
}
.account-wrap__admin .dashboard-content__statistics-name{
  font-size: 14px;
  color: #808a8e;
  line-height: 1.29;
}
@media(min-width: 768px){
  .answer-form__nav{
    display: flex;
    align-items: center;
    margin: 0 -5px;
  }
  .answer-form__nav-col{
    width: 50%;
    padding: 0 5px;
    margin-bottom: 0;
  }
}
.answer-form__nav .field-wrap__submit{
  padding-top: 0;
}
@media(min-width: 768px){
  .header__collapse{
    display: none;
  }
  .header.header__account .header__collapse{
    display: block;
  }
  .header__language{
    display: block;
  }
  .header__language-content{
    display: none;
  }
}
@media(min-width: 992px){
  .header__account{
    display: none;
  }
  .header.header__account .header__collapse{
    display: none;
  }
  .header__language-content{
    display: block;
  }
.account-nav.account-nav.account-nav__admin{
    transform: translateX(0);
  }
  .account-nav.account-nav__admin .account-nav__close-btn{
    display: none;
  }
  .account-wrap__admin .account-content__header{
    padding: 0 30px;
  }
  .account-wrap__admin .account-wgt{
    padding: 5px 15px 30px 15px;
  }
  .account-wrap__admin .account-content__header-wrap{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media(max-width: 991px){
  .account-nav.account-nav__admin.active{
    animation: mobileMenu 0.5s ease-in-out forwards;
  }
  .account-nav.account-nav__admin.disabled{
    animation: mobileMenuDis 0.5s ease-in-out forwards;
  }
}
@media(min-width: 576px){
  .account-wrap__admin .standart-table{
    display: table;
  }
}
@keyframes mobileMenu {
 from { transform: translateX(-100%); }
 to { transform: translateX(0); }
}
@keyframes mobileMenuDis {
 from { transform: translateX(0); }
 to { transform: translateX(-100%); }
}
@keyframes fadeIn {
  0% {
    background: rgba(44, 47, 63, .0);
 }
  100% {
    background: rgba(44, 47, 63, .3);
 }
}
@keyframes fadeOut {
  0% {
    background: rgba(44, 47, 63, .3);
 }
  100% {
    background: rgba(44, 47, 63, .0);
 }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
@keyframes fadeOut2 {
  0% {
    opacity: 1;
 }
  100% {
    opacity: 0;
 }
}
@keyframes scaleUp {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
 }
  100% {
    transform: scale(0.8) translateY(-1000px);
    opacity: 0;
 }
}
@keyframes scaleDown {
  0% {
    transform: scale(0.8) translateY(-1000px);
    opacity: 0;
 }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
 }
}
@keyframes scaleIn {
  0% {
    transform: scale(0.85);
 }
  100% {
    transform: scale(1);
 }
}
@keyframes scaleBack {
  0% {
    transform: scale(1);
 }
  100% {
    transform: scale(0.85);
 }
}
.tea,
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
	 background: none;
}
 .tgl + .tgl-btn {
	 outline: 0;
	 display: block;
	 width: 40px;
	 height: 15px;
	 position: relative;
	 cursor: pointer;
	 user-select: none;
}
 .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
	 position: relative;
	 display: block;
	 content: "";
	 width: 20px;
	 height: 20px;
   margin-top: -5px;
   border: 1px solid #cecece;
}
 .tgl + .tgl-btn:after {
	 left: -1px;
}
 .tgl + .tgl-btn:before {
	 display: none;
}
 .tgl:checked + .tgl-btn:after {
	 left: 50%;
}
 .tgl-light + .tgl-btn {
	 background: #f0f0f0;
	 border-radius: 2em;
	 padding: 2px;
	 transition: all 0.4s ease;
}
 .tgl-light + .tgl-btn:after {
	 border-radius: 50%;
	 background: #fff;
	 transition: all 0.2s ease;
}
 .tgl-light:checked + .tgl-btn {
	 background: #24ce8b;
}
.login-admin{
  height: 100%;
  background-color: var(--main-color-additional);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}
.login-admin__logo{
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
.login-admin__inner{
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
}
@media(min-width: 540px){
  .login-admin{
    align-items: center;
  }
  .login-admin__inner{
    max-width: 380px;
  }
}
.field-wrap__hide-btn{
  background: none;
  border: none;
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 10;
}
.field-wrap__hide-icon{
  width: 21px;
  height: 18px;
  fill: #000;
  display: block;
}
.login-admin__submit{
  padding-top: 10px;
}
.access-label{
  padding: 4px 6px;
  border-radius: 2px;
  font-size: 12px;
  margin-right: 5px;
  display: inline-block;
  background-color: #e6e9f9;
  color: #364ed4;
  border-radius: 5px;
  font-weight: 500;
}
.access-label:last-child{
  margin-right: 0;
}
.group-label{
  font-size: 12px;
  padding: 4px 7px;
  border-radius: 2px;
  background-color: rgba(54,78, 212, 0.2);
  color: #364ed4;
  margin-right: 5px;
  display: inline-block;
  font-weight: 500;
}
.group-label:last-child{
  margin-right: 0;
}
.group-access-wrap{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.group-access-wgt{
  width: 25%;
  flex: 0 0 auto;
  padding: 0 10px 20px 10px;
}
.group-access-wgt__inner{
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.group-access-wgt__headline{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.group-access-wgt ul{
  list-style-type: none;
}
.group-access-wgt ul li{
  padding: 5px 0;
}
.errors-content{
  padding: 0 10px;
}
@media(min-width: 992px){
  .errors-content{
    padding: 0 30px;
  }
}
.bo-status-label{
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 10px;
  color: #364ED4;
  background-color: #F1F3FE;
  display: inline-block;
}
.bo-status-label_theme_yellow{
  background-color: #FFF0E1;
  color: #D87000;
}
.bo-status-label_theme_green{
  background-color: #DFF9EA;
  color: #009D45;
}
.bo-status-label_theme_red{
  background-color: #FFE7EB;
  color: #D01A36 ;
}
.bo-status-label_theme_gray{
  background-color: #e3e6f0;
  color: #757f87 ;
}
.bo-blog-category{
  font-size: 11px;
  font-weight: 500;
  line-height: 1.27;
  color: #00c059;
  padding: 7px 12px;
}
.bo-blog-category_theme_green{
  border-radius: 5px;
  background-color: #e5faef;
}
.account-wgt.account-wgt__blog{
  padding-top: 0;
  height: 100%;
}
.account-wgt.account-wgt__blog-form{
  padding-top: 0;
  padding-bottom: 0;
}
.account-wgt.account-wgt__blog-form:last-child{
  padding-bottom: 30px;
}
.field-wrap__blog-category{
  display: inline-block;
  min-width: 300px;
}
.edit-blog-img{
  display: flex;
  align-items: center;
}
.edit-blog-img:hover .edit-product-img__remove{
  display: block;
}
.edit-blog-img div{
  max-width: 140px;
  height: auto;
  display: flex;
  position: relative;
}
@media(min-width: 992px){
  .account-wrap__admin .account-wgt__dashboard{
    padding: 5px 30px 30px 30px;
  }
  .account-wrap__admin .account-content__wgt{
    padding: 15px 30px 15px 30px;
  }
}
.account-content__wgt_n_b{
  border-bottom: none;
}
.bo-services-content_company{
  padding-top: 15px;
  padding-bottom: 15px;
}
.bo-services-content__inner{
  background-color: #f2f4f5;
}
.bo-services-content-item{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.bo-services-content-item__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 12px;
}
.bo-services-content-item__headline{
  color: #000;
  font-size: 16px;
  font-weight: 900;
}
.user-statistic__info-wgt-headline-balance{
  color: #808a8e;
  font-size: 14px;
}
.user-statistic__info-wgt-balance-wrap{
  display: flex;
  align-items: center;
  padding-top: 2px;
}
.user-statistic__info-wgt-balance-value{
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding-right: 25px;
}
.user-statistic__info-wgt-balance-value:last-child{
  padding-right: 0;
}
.account-content__nav-company{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
